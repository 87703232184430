var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrapper"
  }, [_c('h2', [_vm._v(" Personalized Sites "), _c('i', {
    staticClass: "fas fa-edit site-edit-icon",
    attrs: {
      "title": "Edit",
      "aria-hidden": "true"
    },
    on: {
      "click": _vm.showEditModal
    }
  }), _c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Edit")])]), _c('p', [_vm._v("Enter and edit landing page url here")]), _vm.personalizedSites.length ? _c('div', {
    staticClass: "information-form mt-4"
  }, _vm._l(_vm.personalizedSites, function (sites, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex site-detail"
    }, [sites.image ? _c('div', {
      staticClass: "site-img"
    }, [_c('img', {
      staticClass: "img-fluid",
      attrs: {
        "src": sites.image,
        "alt": ""
      }
    })]) : _vm._e(), _c('div', {
      staticClass: "site-info"
    }, [_c('h3', [_vm._v(_vm._s(sites.name))]), _c('span', {
      staticClass: "url-site"
    }, [_c('a', {
      attrs: {
        "href": 'https://' + sites.domain,
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(sites.domain))])])])])]);
  }), 0) : _c('div', [_c('p', {
    staticClass: "text-danger"
  }, [_vm._v("Currently there are no personalized sites")])]), _c('personalized-sites-update-modal', {
    attrs: {
      "web-access-code": _vm.generalInfo.group_web,
      "group-id": _vm.groupId
    },
    on: {
      "onSuccess": _vm.getSites
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="addAddress-popup">
    <b-modal
      id="employeraddAddress"
      centered
      size="lg"
      hide-footer
      no-close-on-backdrop
    >
      <template #modal-header>
        <div class="addAddress-header">
          <h4>Add New Address</h4>
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="addAddress-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.prevent="handleSubmit(addAddress)"
              ref="addAddressContainer"
            >
              <div class="form-group row">
                <input-text
                  :rules="{ required: true }"
                  labelFor="address1"
                  labelName="Address Line 1"
                  inputName="address1"
                  inputClass="address1 info-field"
                  :vmodel.sync="form.address1"
                  formGroupClass="col-xxl-6 col-lg-6 col-md-6 mb-3"
                />

                <input-text
                  :rules="{ required: false }"
                  labelFor="address2"
                  labelName="Address Line 2"
                  inputName="address2"
                  inputClass="address2 info-field"
                  :vmodel.sync="form.address2"
                  formGroupClass="col-xxl-6 col-lg-6 col-md-6 mb-3"
                />
              </div>
              <div class="form-group row">
                <input-text
                  :rules="{ required: true }"
                  labelFor="city"
                  labelName="City"
                  inputName="city"
                  inputClass="city info-field"
                  :vmodel.sync="form.city"
                  formGroupClass="col-xxl-6 col-lg-6 col-md-6 mb-3"
                />
                <input-multi-select
                  :rules="{ required: true }"
                  :vmodel.sync="form.state"
                  labelName="State"
                  :options="states.map((type) => type.value)"
                  :custom-label="
                    (opt) => states.find((x) => x.value == opt).text
                  "
                  formGroupClass="col-xxl-6 col-lg-6 col-md-6 mb-3"
                  placeholder="Select State"
                  inputClass="state"
                />
              </div>

              <div class="form-group row">
                <input-text
                  :rules="{ required: true, digits: 5 }"
                  labelFor="zip"
                  labelName="Zip"
                  inputName="zip"
                  :vmodel.sync="form.zip"
                  type="number"
                  formGroupClass="col-xxl-6 col-lg-6 col-md-6 mb-3"
                  inputClass="zip info-field"
                />
              </div>
              <div class="form-group row">
                <div class="form-check col-xxl-6 col-lg-6 col-md-6 mb-3">
                  <toggle-element
                    :vmodel.sync="form.is_primary"
                    @onClicked="form.is_primary = !form.is_primary"
                    text="Set as Primary"
                  />
                </div>
                <div class="form-check col-xxl-6 col-lg-6 col-md-6 mb-3">
                  <input
                    class="form-check-input add-new-address-checkbox"
                    type="checkbox"
                    id="flexCheckDefault"
                    v-model="continue_with_address_validation"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Validate with USPS
                  </label>
                </div>
              </div>
              <div class="w-100 text-center">
                <b-button class="univ-btn" type="submit">
                  {{ isCreate ? "Creating ..." : "Create" }}
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
const form = {
  address1: "",
  address2: "",
  city: "",
  state: null,
  zip: "",
  type: null,
  usps_verified: false,
  is_primary: false,
  group_id: "",
};
export default {
  name: "EmployerGroupAddressAdd",
  props: {
    type: {
      required: true,
    },
  },
  data: () => ({
    form: { ...form },
    addressTypes: Helper.getAddressTypes(),
    states: Helper.getStates(),
    isCreate: false,
    continue_with_address_validation: false,
  }),
  methods: {
    closeModal() {
      this.form = { ...form };
      this.form.group_id = atob(this.$route.params.groupId);
      this.$bvModal.hide("employeraddAddress");
    },
    reset() {
      this.form = { ...form };
      this.form.group_id = atob(this.$route.params.groupId);
      this.$refs.observer.reset();
      this.$bvModal.hide("employeraddAddress");
      this.$emit("onSuccess");
    },
    validateUsps() {
      let url = this.$endpoint.API_URL + `v1/validate-address-usps`;
      const uspsAxios = axios.create();
      this.isCreate = true;
      let loader = this.$loading.show({
        container: this.$refs.addAddressContainer,
      });
      uspsAxios
        .post(url, this.form)
        .then(() => {
          this.form.usps_verified = true;
          this.createAddress();
        })
        .catch((err) => {
          let errorMessage = err.response.data.data[0].error_message;
          this.$notify(errorMessage, "error");
          setTimeout(() => {
            this.$refs.observer.reset();
          }, 2000);
        })
        .finally(() => {
          loader.hide()
          this.isCreate = false;
        });
    },
    createAddress() {
      this.isCreate = true;
      const url = `v2/add-group-address`;
      let loader = this.$loading.show({
        container: this.$refs.addAddressContainer,
      });
      this.form.type = this.type;
      Api.post(url, this.form)
        .then((res) => {
          this.reset();
          this.$notify(res.data.message);
          this.$emit("onSuccess")
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isCreate = false;
          loader.hide();
        });
    },
    addAddress() {
      this.form.group_id = atob(this.$route.params.groupId);
      if (this.continue_with_address_validation) {
        this.validateUsps();
      } else {
        this.createAddress();
      }
    },
  },
};
</script>
<style>
#employeraddAddress .modal-content {
  border: 0px;
  border-radius: 10px;
}
#employeraddAddress .modal-header {
  justify-content: center;
  border: 0;
  padding: 25px 0;
  position: relative;
  display: flex !important;
}
#employeraddAddress .modal-footer {
  border-top: 0;
  padding: 0 15px 25px 15px;
}
.addAddress-header h4 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 600;
  margin: 0;
}
.addAddress-header button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.addAddress-form label {
  color: #5e5e5e;
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 500;
}
#employeraddAddress .modal-footer .univ-btn {
  max-width: 300px;
}
</style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "editInfo-wrap"
  }, [_c('h2', [_vm._v("Billing Address")]), _c('p', [_vm._v("Enter and Update all your Billing Address here.")]), _c('div', {
    staticClass: "information-form"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "employerGroupGeneralInfoContainer",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.updateInfo);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "first_name",
            "labelName": "Billing Contact First Name",
            "vmodel": _vm.form.first_name,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "first_name", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "last_name",
            "labelName": "Billing Contact Last Name",
            "vmodel": _vm.form.last_name,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "last_name", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: true,
              digits: 10,
              clearoutPhone: true
            },
            "labelFor": "billing_phone",
            "labelName": "Phone",
            "vmodel": _vm.form.phone,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3",
            "placeholder": "Phone (no dashes eg: 123456789)"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "phone", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: true,
              neverbounce: true
            },
            "labelFor": "billing_email",
            "labelName": "Email",
            "vmodel": _vm.form.email,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "email", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: false
            },
            "labelFor": "billing_fax",
            "labelName": "FAX",
            "vmodel": _vm.form.fax,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3",
            "placeholder": "FAX (no dashes eg: 123456789)"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "fax", $event);
            }
          }
        })], 1), _c('employer-group-billing-address-content', {
          attrs: {
            "addresses": _vm.billingAddresses,
            "type": "billing"
          }
        }), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12"
        }, [_c('button', {
          staticClass: "univ-btn",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.isUpdate ? "Updating" : "Update") + " ")])])])], 1)];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
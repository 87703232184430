var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "editInfo-wrap"
  }, [_c('h2', [_vm._v("General Information")]), _c('p', [_vm._v("Enter and Update all your General Information here.")]), _c('div', {
    staticClass: "information-form"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "employerGroupGeneralInfoContainer",
          on: {
            "submit": function submit($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return handleSubmit(_vm.updateInfo);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('input-text', {
          attrs: {
            "labelFor": "group_code",
            "labelName": "Group Code",
            "vmodel": _vm.form.group_code,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3",
            "readonly": ""
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "group_code", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "group_name",
            "labelName": "Group Name ",
            "vmodel": _vm.form.group_name,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "group_name", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: true,
              neverbounce: true
            },
            "labelFor": "email",
            "labelName": "Email",
            "vmodel": _vm.form.email,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "email", $event);
            }
          }
        })], 1), _c('div', {
          staticClass: "form-group row"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "first_name",
            "labelName": "First Name  ",
            "vmodel": _vm.form.first_name,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "first_name", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "last_name",
            "labelName": "Last Name  ",
            "vmodel": _vm.form.last_name,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "last_name", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: true,
              digits: 10,
              clearoutPhone: true
            },
            "labelFor": "phone",
            "labelName": "Phone",
            "vmodel": _vm.form.phone,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "phone", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: true
            },
            "labelFor": "tax_id",
            "labelName": "Tax ID",
            "vmodel": _vm.form.tax_id,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "tax_id", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: false
            },
            "labelFor": "fax",
            "labelName": "Fax",
            "vmodel": _vm.form.fax,
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "fax", $event);
            }
          }
        }), _c('input-multi-select', {
          attrs: {
            "labelFor": "industry",
            "labelName": "Industry",
            "inputName": "industry",
            "vmodel": _vm.form.industry,
            "options": _vm.groupIndustries.map(function (type) {
              return type.value;
            }),
            "custom-label": function customLabel(opt) {
              return _vm.groupIndustries.find(function (x) {
                return x.value == opt;
              }).title;
            },
            "formGroupClass": "col-xxl-4 col-lg-4 col-md-6 mb-3",
            "inputClass": "group-industry",
            "placeholder": "Select Industry"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "industry", $event);
            }
          }
        })], 1), _c('div', {
          staticClass: "form-group row image-row"
        }, [_c('input-image', {
          ref: "groupLogo",
          attrs: {
            "labelFor": "group_logo",
            "labelName": "Logo",
            "vmodel": _vm.group_logo,
            "formGroupClass": "col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4",
            "imgSrc": _vm.groupLogoSrc,
            "imgClass": "group-upload-img"
          },
          on: {
            "deleteImage": function deleteImage($event) {
              return _vm.deleteImage('logo');
            },
            "update:vmodel": function updateVmodel($event) {
              _vm.group_logo = $event;
            }
          }
        })], 1), _c('div', {
          staticClass: "form-group row image-row"
        }, [_c('input-image', {
          ref: "coverImage",
          attrs: {
            "labelFor": "cover_photo",
            "labelName": "Cover Image",
            "vmodel": _vm.cover_photo,
            "formGroupClass": "col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4",
            "imgSrc": _vm.groupCoverSrc,
            "imgClass": "group-upload-img"
          },
          on: {
            "deleteImage": function deleteImage($event) {
              return _vm.deleteImage('cover');
            },
            "update:vmodel": function updateVmodel($event) {
              _vm.cover_photo = $event;
            }
          }
        })], 1), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12"
        }, [_c('button', {
          staticClass: "univ-btn",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.isUpdate ? "Updating..." : "Update") + " ")])])])])];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="info-wrapper">
    <h2>
      Personalized Sites
      <i
        title="Edit"
        class="fas fa-edit site-edit-icon"
        aria-hidden="true"
        @click="showEditModal"
      ></i
      ><span class="sr-only">Edit</span>
    </h2>
    <p>Enter and edit landing page url here</p>
    <div class="information-form mt-4" v-if="personalizedSites.length">
      <div v-for="(sites, index) in personalizedSites" :key="index">
        <div class="d-flex site-detail">
          <div class="site-img" v-if="sites.image">
            <img :src="sites.image" alt="" class="img-fluid" />
          </div>
          <div class="site-info">
            <h3>{{ sites.name }}</h3>
            <span class="url-site"
              ><a :href="'https://'+sites.domain" target="_blank">{{
                sites.domain
              }}</a></span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p class="text-danger">Currently there are no personalized sites</p>
    </div>
    <personalized-sites-update-modal
      :web-access-code="generalInfo.group_web"
      :group-id="groupId"
      @onSuccess="getSites"
    />
  </div>
</template>

<script>
import Api from "@/resource/Api";
import PersonalizedSitesUpdateModal from "../modals/PersonalizedSitesUpdateModal.vue";

export default {
  name: "EmployerGroupPersonalizedSites",
  components: {
    PersonalizedSitesUpdateModal,
  },
  props: {
    generalInfo: {
      required: true,
    },
  },
  data() {
    return {
      personalizedSites: [],
      groupId: "",
    };
  },
  methods: {
    showEditModal() {
      this.$bvModal.show("group-personalized-sites-modal");
    },
    async getSites() {
      const url = `v2/group-personalized-site/get?group_id=${this.groupId}`;
      await Api.get(url)
        .then((res) => {
          if (res.status == 200) {
            this.personalizedSites = res.data.data;
            // this.webAccessCode = res.data.data.web_access_code ?? "";
          }
        })
        .catch(() => {
          this.personalizedSites = [];
        });
    },
  },
  created() {
    this.groupId = window.atob(this.$route.params.groupId);
    this.getSites();
  },
};
</script>

<style scoped>
.site-detail {
  padding: 10px 0;
}
.site-img {
  max-width: 80px;
  max-height: 80px;
  margin-right: 18px;
  overflow-y: hidden;
}

.site-edit-icon {
  margin-left: 3px;
  color: #03c3ec;
  cursor: pointer;
}
.information-form .site-detail {
  border: 1px solid #ebebeb;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.information-form .site-detail .site-img img {
  padding-top: 8px;
}
.information-form .site-detail h3 {
  margin-bottom: 5px;
}
.information-form .site-detail a {
  font-size: 14px;
}
.info-wrapper h2 {
  font-size: 20px;
  color: #2c3f58;
  font-weight: 700;
}
.info-wrapper p {
  font-size: 14px;
  color: #778699;
}
.info-wrapper h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.information-form .site-detail h3 {
  margin-bottom: 5px;
}
.information-form .site-detail a {
  font-size: 14px;
  color: #254050;
}
@media (max-width: 1400px) {
  .info-wrapper h2 {
    font-size: 18px;
  }
}
</style>
<style>
#updatePersonalizedSite .modal-header {
  display: flex !important;
}
</style>

<template>
  <div class="form-group row ai-location-wrapper" v-if="addresses">
    <template v-if="type == 'billing'">
      <label>
        Address Information
        <i
          class="fas fa-plus-circle add-address-btn"
          @click="$bvModal.show('employeraddAddress')"
        >
        </i>
      </label>
    </template>
    <div
      class="col-md-6 col-sm-12 mb-3"
      v-for="(address, index) in addresses"
      :key="index"
    >
      <div
        class="location-div"
        :class="address.is_primary ? 'primary-location' : 'set-as-primary'"
      >
        <p>
          {{ address.address1 }},
          <i
            :class="
              address.is_usps_valid
                ? 'fas fa-check-circle'
                : 'fas fa-exclamation-triangle'
            "
            v-b-tooltip.hover
            :title="address.is_usps_valid ? 'Verified' : 'Not Verified'"
          />
          <br />
          {{
            formatAddress2(
              address.address2,
              address.city,
              address.state,
              address.zip
            )
          }}
        </p>

        <template v-if="address.is_primary">
          <button class="primaryAdrs-btn">Primary</button>
        </template>
        <template v-else>
          <button
            href="javascript:void(0);"
            @click.prevent="setPrimary(address.id, type)"
            class="primaryAdrs-btn"
          >
            Set As Primary
          </button>
        </template>
        <custom-button
          v-if="!address.is_primary"
          className="ai-delete-a"
          title="Delete"
          iconClass="fas fa-trash"
          @click.native="deleteAddress(address.id, type)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
export default {
  name: "EmployerGroupBillingAddressContent",
  props: {
    addresses: {
      required: true,
    },
    type: {
      required: true,
    },
  },
  methods: {
    emitEvent(type) {
      if (type == "billing") {
        this.$parent.$parent.$emit("onSuccess");
      } else {
        this.$parent.$emit("onSuccess");
      }
    },
    formatAddress2(address2, city, state, zip) {
      return Helper.formattedAddress2(address2, city, state, zip);
    },
    setPrimary(id, type) {
      console.log("heri");
      const url = `v2/set-group-primary-address`;
      this.$confirm({
        title: "Set Primary",
        message: `Do you want to set this address primary?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            let groupId = parseInt(atob(this.$route.params.groupId));
            Api.post(url, { group_id: groupId, address_id: id, type: type })
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.emitEvent(type);
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
    deleteAddress(id, type) {
      const url = `v2/delete-group-address`;
      this.$confirm({
        title: "Delete Address",
        message: `Do you want to delete this address?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            let groupId = parseInt(atob(this.$route.params.groupId));
            Api.delete(url, { group_id: groupId, address_id: id, type: type })
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.emitEvent(type);
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "error");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
};
</script>
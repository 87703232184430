<template>
  <div class="editInfo-wrap">
    <h2>General Information</h2>

    <p>Enter and Update all your General Information here.</p>
    <div class="information-form">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.stop.prevent="handleSubmit(updateInfo)"
          ref="employerGroupGeneralInfoContainer"
        >
          <div class="form-group row">
            <input-text
              labelFor="group_code"
              labelName="Group Code"
              :vmodel.sync="form.group_code"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
              readonly
            />
            <input-text
              :rules="{ required: true }"
              labelFor="group_name"
              labelName="Group Name "
              :vmodel.sync="form.group_name"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
            />
            <input-text
              :rules="{ required: true, neverbounce: true }"
              labelFor="email"
              labelName="Email"
              :vmodel.sync="form.email"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
            />
          </div>
          <div class="form-group row">
            <input-text
              :rules="{ required: true }"
              labelFor="first_name"
              labelName="First Name  "
              :vmodel.sync="form.first_name"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
            />
            <input-text
              :rules="{ required: true }"
              labelFor="last_name"
              labelName="Last Name  "
              :vmodel.sync="form.last_name"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
            />
            <input-text
              :rules="{ required: true, digits: 10, clearoutPhone: true }"
              labelFor="phone"
              labelName="Phone"
              :vmodel.sync="form.phone"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
            />
            <input-text
              :rules="{ required: true }"
              labelFor="tax_id"
              labelName="Tax ID"
              :vmodel.sync="form.tax_id"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
            />
            <input-text
              :rules="{ required: false }"
              labelFor="fax"
              labelName="Fax"
              :vmodel.sync="form.fax"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
            />
            <input-multi-select
              labelFor="industry"
              labelName="Industry"
              inputName="industry"
              :vmodel.sync="form.industry"
              :options="groupIndustries.map((type) => type.value)"
              :custom-label="
                (opt) => groupIndustries.find((x) => x.value == opt).title
              "
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
              inputClass="group-industry"
              placeholder="Select Industry"
            />
          </div>

          <div class="form-group row image-row">
            <input-image
              @deleteImage="deleteImage('logo')"
              ref="groupLogo"
              labelFor="group_logo"
              labelName="Logo"
              :vmodel.sync="group_logo"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4"
              :imgSrc="groupLogoSrc"
              imgClass="group-upload-img"
            />

          </div>

        <div class="form-group row image-row">
            <input-image
             @deleteImage="deleteImage('cover')"
              ref="coverImage"
              labelFor="cover_photo"
              labelName="Cover Image"
              :vmodel.sync="cover_photo"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-4"
              :imgSrc="groupCoverSrc"
              imgClass="group-upload-img"
            />

          </div>

          <div class="row">
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
              <button type="submit" class="univ-btn">
                {{ isUpdate ? "Updating..." : "Update" }}
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";

export default {
  name: "EmployerGroupGeneralInfo",
  props: {
    generalInfo: {
      required: true,
    },
    groupIndustries: {
      required: true,
    },
  },
  data: () => ({
    form: {
      group_id: "",
      group_code: "",
      group_name: "",
      group_landing_page: "",
      tax_id: "",
      industry: null,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      fax: "",
    },
    isUpdate: false,
    group_logo: null, 
    cover_photo: null, 
    groupLogoSrc: null,
    groupCoverSrc: null,
  }),
  methods: {
    updateInfo() {
      this.isUpdate = true;
      const url = `v2/update-group-details?type=general`;
      let loader = this.$loading.show();
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          formData.append(key, value);
        }
      }
      if (this.group_logo) {
        formData.append("group_logo", this.group_logo);
      }
         if (this.cover_photo) {
        formData.append("cover_photo", this.cover_photo);
      }
      Api.post(url, formData)
        .then((res) => {
          this.$notify(res.data.message);
          this.$emit("onSuccess");
          this.$refs.groupLogo.reset();

        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isUpdate = false;
          loader.hide();
          this.$refs.observer.reset();
        });
    },
    setFormData(value) {
      this.form = {
        group_id: value.group_id ? value.group_id : "",
        group_code: value.group_code ? value.group_code : "",
        group_name: value.group_name ? value.group_name : "",
        group_landing_page: value.group_landing_page
          ? value.group_landing_page
          : "",
        tax_id: value.tax_id ? value.tax_id : "",
        industry: value.industry ? value.industry : null,
        first_name: value.first_name ? value.first_name : "",
        last_name: value.last_name ? value.last_name : "",
        email: value.email ? value.email : "",
        phone: value.group_phone ? value.group_phone : "",
        fax: value.group_fax ? value.group_fax : "",
      };
      this.groupLogoSrc = value.group_logo ? value.group_logo : "";
      this.groupCoverSrc = value.cover_photo ? value.cover_photo : "";
    },
  async deleteImage(type = '') {
       const del = await Helper.confirmDialog('Confirm', 'Are you sure to delete this image?');
       if (del) {
          let url = (type =='logo') ? 'remove-group-logo' : 'remove-group-cover-image';
           return Api.delete(`v2/${url}/${this.form.group_id}`)
             .then((response) => {
                  this.$emit("onSuccess")
                   Api.notification(response.data.status, response.data.message);     
              })
                .catch (e => {
                  console.log(e);
                    Api.notification('error', 'Failed to delete image'); 
                });
            }
        },
  },
  watch: {
    generalInfo: function (value) {
      if (value) {
        this.setFormData(value);
      }
    },
  },
};
</script>
<style>
.image-row {
  display: inline-block;
  width: 50%;
}
.custom-file-input {
  width: 120% !important;
}

.uploaded {
    width: 116%;
    position: relative;
}
</style>
<template>
  <b-modal
    id="group-personalized-sites-modal"
    centered
    size="md"
    v-model="show"
    no-close-on-backdrop
  >
    <b-button variant="close-btn" @click="closeModal">
      <i class="fas fa-times"></i>
    </b-button>
    <div class="title-text mb-3">
      <h5><strong>Edit Web Access Code</strong></h5>
    </div>
    <div class="update-personalized-sites-form">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form @submit.stop.prevent="handleSubmit(submit)" ref="formContainer">
          <div class="form-group row">
            <input-text
              :rules="{ required: true, alpha_num: true }"
              labelFor="web_access"
              labelName="Web Access Code"
              :vmodel.sync="web_access"
              formGroupClass="col-lg-12"
            />
          </div>
          <div
            class="
              form-group
              text-center
              col-xxl-12
              personalized-site-submit
              my-3
            "
          >
            <b-button class="univ-btn" type="submit" :disabled="isUpdate">
              <template v-if="isUpdate"> Updating ... </template>
              <template v-else> Update </template>
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>
<script>
import Api from "@/resource/Api";

export default {
  props: {
    webAccessCode: {
      required: false,
      default: "",
    },
    groupId:{
      required: true
    }
  },
  data: () => {
    return {
      show: false,
      date: "",
      variants: ["close-btn"],
      web_access: "",
      isUpdate: false,
    };
  },
  methods: {
    submit() {
      this.isUpdate = true;
      const url = `v2/group-personalized-site/update`;
      let loader = this.$loading.show({
        container: this.$refs.formContainer,
      });
      Api.put(url, { web_access: this.web_access, group_id: this.groupId })
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
          this.$emit("onSuccess");
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
          this.$refs.observer.reset();
        })
        .finally(() => {
          this.isUpdate = false;
          loader.hide();
        });
    },
    closeModal() {
      this.$emit("onCloseModal");
      this.$bvModal.hide("group-personalized-sites-modal");
    },
  },
  watch: {
    webAccessCode(code) {
      this.web_access = code;
    },
  },
};
</script>
<style>
.update-personalized-sites-form label {
  margin-bottom: 8px !important;
}
#group-personalized-sites-modal .modal-header,
#group-personalized-sites-modal .modal-footer {
  display: none;
}
#group-personalized-sites-modal .modal-content {
  border: 0px;
  border-radius: 10px;
}
.btn-close-btn {
  position: absolute;
  top: -15px;
  right: -15px;
  background: #fff;
  color: #000;
  border: 0;
  box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
  border-radius: 60px;
  font-size: 18px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>

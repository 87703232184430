var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-wrapper"
  }, [_c('h2', [_vm._v("Display Setting")]), _c('p', [_vm._v("Enter and Update all your Display Setting here.")]), _c('div', {
    staticClass: "information-form mt-4"
  }, [_c('ValidationObserver', {
    ref: "observer",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          ref: "downlineRepDisplaySettingContainer",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.updateInfo);
            }
          }
        }, [_c('div', {
          staticClass: "form-group row"
        }, [_c('input-text', {
          attrs: {
            "rules": {
              required: false
            },
            "labelFor": "tagline",
            "labelName": "Tagline ",
            "vmodel": _vm.form.tagline,
            "formGroupClass": "col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "tagline", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: false,
              digits: 10,
              clearoutPhone: true
            },
            "labelFor": "display_phone",
            "labelName": "Display Phone ",
            "vmodel": _vm.form.display_phone,
            "formGroupClass": "col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "display_phone", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: false,
              neverbounce: true
            },
            "labelFor": "display_email",
            "labelName": "Display Email ",
            "vmodel": _vm.form.display_email,
            "formGroupClass": "col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "display_email", $event);
            }
          }
        }), _c('input-text', {
          attrs: {
            "rules": {
              required: false
            },
            "labelFor": "group_appointment_url",
            "labelName": "Appointment Link",
            "vmodel": _vm.form.appointment_url,
            "formGroupClass": "col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
          },
          on: {
            "update:vmodel": function updateVmodel($event) {
              return _vm.$set(_vm.form, "appointment_url", $event);
            }
          }
        }), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-xxl-6 mt-3 my-profile-bio"
        }, [_c('div', {
          staticClass: "profileInfo-block"
        }, [_c('h6', [_vm._v("Bio")]), _c('div', {
          staticClass: "form-group mb-3"
        }, [_c('textarea', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.display_about_page,
            expression: "form.display_about_page"
          }],
          staticClass: "profileInfo-field form-control",
          attrs: {
            "placeholder": "Enter your Bio here...",
            "rows": "7"
          },
          domProps: {
            "value": _vm.form.display_about_page
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(_vm.form, "display_about_page", $event.target.value);
            }
          }
        })])])])])], 1), _vm.display_setting_image ? _c('div', {
          staticClass: "form-group row"
        }, [_c('div', {
          staticClass: "col-xxl-6 col-xl-6 col-lg-6 col-md-12 mb-4"
        }, [_c('figure', [_vm._v("Where can I see this information?")]), _c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": _vm.display_setting_image
          }
        })])]) : _vm._e(), _c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-xxl-3 col-xl-3 col-lg-3 col-md-6"
        }, [_c('button', {
          staticClass: "univ-btn",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.isUpdate ? "Updating..." : "Update") + " ")])])])])];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
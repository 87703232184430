var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "editInfo-wrap"
  }, [_c('h2', [_vm._v(" Address Information "), _c('i', {
    staticClass: "fas fa-plus-circle add-address-btn",
    on: {
      "click": function click($event) {
        return _vm.$bvModal.show('employeraddAddress');
      }
    }
  })]), _c('p', [_vm._v("Update all your Address Information here.")]), _c('div', {
    staticClass: "information-form"
  }, [_c('employer-group-address-content', {
    attrs: {
      "type": "general",
      "addresses": _vm.addresses
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="editInfo-wrap">
    <h2>
      Address Information
      <i
        class="fas fa-plus-circle add-address-btn"
        @click="$bvModal.show('employeraddAddress')"
      >
      </i>
    </h2>
    <p>Update all your Address Information here.</p>
    <div class="information-form">
      <employer-group-address-content type="general" :addresses="addresses"/>
    </div>
  </div>
</template>
<script>
import Helper from "@/resource/Helper";
import EmployerGroupAddressContent from './partials/EmployerGroupAddressContent.vue';
export default {
  components: { EmployerGroupAddressContent },
  name: "EmployerGroupAddressInfo",
  props: {
    addresses: {
      required: true,
    },
  },
  methods: {
    formatAddress2(address2, city, state, zip) {
      return Helper.formattedAddress2(address2, city, state, zip);
    },
  },
};
</script>
<style>
.ai-location-wrapper {
  margin-top: 15px;
}
.ai-location-wrapper label {
  font-size: 16px !important;
  font-weight: bold;
}
.location-div {
  border: 2px solid #dce1e7;
  padding: 10px;
  border-radius: 6px;
}
.location-div p {
  display: inline-block;
  margin-bottom: 0;
}
.location-div button {
  float: right;
  margin-top: 3px;
  padding: 6px 12px;
  margin-right: 35px;
}
.location-div.primary-location {
  border: 2px solid #03c3ec;
}
.location-div.primary-location .primaryAdrs-btn {
  pointer-events: none;
}
.location-div.set-as-primary .primaryAdrs-btn {
  border: 2px solid #03c3ec;
  background-color: #fff;
  color: #03c3ec;
}
.location-div.set-as-primary .primaryAdrs-btn:hover {
  border: 2px solid #03c3ec;
  background-color: #03c3ec;
  color: #fff;
}
.fa-check-circle {
  color: #02bc77;
}
.fa-plus-circle {
  color: #03c3ec;
  font-size: 16px;
  margin-left: 3px;
  vertical-align: revert;
  margin-bottom: 5px;
}
.fa-exclamation-triangle {
  color: #f29423;
}

.location-div {
  position: relative;
}
.location-div .ai-delete-a {
  background: transparent linear-gradient(180deg, #0cf, #1597b8 49%, #0567bc);
  border-radius: 50px;
  width: 27px;
  height: 27px;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 10px 8px;
  position: absolute;
  right: 2px;
  top: 6px;
}
.form-ach .action-icons .primaryAdrs-btn {
  margin-right: 2px;
}
.form-ach .action-icons a {
  margin: 7px;
}

@media (max-width: 850px) and (min-width: 466px) {
  .location-div .primaryAdrs-btn {
    margin-right: 27px;
    padding: 6px 6px;
  }
  .location-div .ai-delete-a {
    margin: 10px 2px;
  }
}
@media (max-width: 465px) {
  .location-div .primaryAdrs-btn {
    padding: 6px 6px;
    float: none;
    margin-right: 0px !important;
    width: 80%;
    margin-top: 15px;
  }
  .location-div .ai-delete-a {
    top: unset !important;
    bottom: 5px;
  }
  .ai-image-wrapper .img-fluid {
    margin-right: 2px;
  }
  .ai-image-wrapper input {
    margin-left: 45px !important;
  }
  .ai-image-wrapper input::before {
    padding: 8px 10px;
    margin-right: -10px;
  }
  .downlineEdit-card {
    padding: 1rem;
  }
}
</style>
var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper employerinfo-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _vm._m(0), _c('div', {
    staticClass: "employerinfo-card"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-tabs', {
    attrs: {
      "card": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "General Information",
      "active": ""
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 0;
        _vm.addressType = null;
      }
    }
  }, [_c('b-card-text', [_c('employer-group-general-info', {
    attrs: {
      "generalInfo": _vm.generalInfo,
      "group-industries": _vm.groupIndustries
    },
    on: {
      "onSuccess": _vm.getDetail
    }
  })], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Address Information"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 1;
        _vm.addressType = 'general';
      }
    }
  }, [_c('b-card-text', [_c('employer-group-address-info', {
    attrs: {
      "addresses": _vm.personalAddresses
    },
    on: {
      "onSuccess": _vm.getDetail
    }
  })], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Billing Address"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 2;
        _vm.addressType = 'billing';
      }
    }
  }, [_c('b-card-text', [_c('empolyer-group-billing-address-info', {
    attrs: {
      "billingInfo": _vm.billingInfo,
      "billingAddresses": _vm.billingAddresses
    },
    on: {
      "onSuccess": _vm.getDetail
    }
  })], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Display Setting"
    },
    on: {
      "click": function click($event) {
        _vm.tabIndex = 2;
        _vm.addressType = null;
      }
    }
  }, [_c('b-card-text', [_c('EmployerGroupDisplaySetting', {
    attrs: {
      "settings": _vm.displaySettings,
      "appointmentUrl": _vm.appointmentUrl,
      "tabIndex": "2"
    }
  })], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Personalized Sites"
    }
  }, [_c('b-card-text', [_c('EmployerGroupPersonalizedSites', {
    attrs: {
      "general-info": _vm.generalInfo
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('employer-group-address-add', {
    attrs: {
      "type": _vm.addressType
    },
    on: {
      "onSuccess": _vm.getDetail
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Employers & Groups Edit Info")])]);
}]

export { render, staticRenderFns }
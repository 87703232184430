<template>
  <div class="editInfo-wrap">
    <h2>Billing Address</h2>
    <p>Enter and Update all your Billing Address here.</p>
    <div class="information-form">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(updateInfo)"
          ref="employerGroupGeneralInfoContainer"
        >
          <div class="form-group row">
            <input-text
              :rules="{ required: true }"
              labelFor="first_name"
              labelName="Billing Contact First Name"
              :vmodel.sync="form.first_name"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
            />
            <input-text
              :rules="{ required: true }"
              labelFor="last_name"
              labelName="Billing Contact Last Name"
              :vmodel.sync="form.last_name"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
            />
            <input-text
              :rules="{ required: true, digits: 10, clearoutPhone: true }"
              labelFor="billing_phone"
              labelName="Phone"
              :vmodel.sync="form.phone"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
              placeholder="Phone (no dashes eg: 123456789)"
            />
            <input-text
              :rules="{ required: true, neverbounce: true }"
              labelFor="billing_email"
              labelName="Email"
              :vmodel.sync="form.email"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
            />

            <input-text
              :rules="{ required: false }"
              labelFor="billing_fax"
              labelName="FAX"
              :vmodel.sync="form.fax"
              formGroupClass="col-xxl-4 col-lg-4 col-md-6 mb-3"
              placeholder="FAX (no dashes eg: 123456789)"
            />
          </div>

          <employer-group-billing-address-content
            :addresses="billingAddresses"
            type="billing"
          />

          <div class="row">
            <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-12">
              <button type="submit" class="univ-btn">
                {{ isUpdate ? "Updating" : "Update" }}
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import EmployerGroupBillingAddressContent from "./partials/EmployerGroupAddressContent.vue";
export default {
  components: { EmployerGroupBillingAddressContent },
  name: "EmployerGroupBillingAddressInfo",
  props: {
    billingInfo: {
      required: true,
    },
    billingAddresses: {
      required: true,
    },
  },
  data: () => ({
    form: {
      group_id: "",
      phone: "",
      fax: "",
      email: "",
      first_name: "",
      last_name: "",
    },
      isUpdate:false
  }),
  methods: {
    updateInfo() {
      this.isUpdate = true;
      const url = `v2/update-group-details?type=billing`;
      let loader = this.$loading.show();
      this.form.group_id = atob(this.$route.params.groupId);
      Api.post(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
          this.$emit("onSuccess");
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isUpdate = false;
          loader.hide();
          this.$refs.observer.reset();
        });
    },
  },
  watch: {
    billingInfo: function (value) {
      if (value) {
        this.form = {
          first_name: value.first_name,
          last_name: value.last_name,
          phone: value.billing_phone,
          fax: value.billing_fax,
          email: value.billing_email,
        };
      }
    },
  },
};
</script>
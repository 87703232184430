<template>
  <div class="info-wrapper">
    <h2>Display Setting</h2>
    <p>Enter and Update all your Display Setting here.</p>
    <div class="information-form mt-4">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form
          @submit.prevent="handleSubmit(updateInfo)"
          ref="downlineRepDisplaySettingContainer"
        >
          <div class="form-group row">
            <input-text
              :rules="{ required: false }"
              labelFor="tagline"
              labelName="Tagline "
              :vmodel.sync="form.tagline"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />

            <input-text
              :rules="{ required: false, digits: 10, clearoutPhone: true }"
              labelFor="display_phone"
              labelName="Display Phone "
              :vmodel.sync="form.display_phone"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />
            <input-text
              :rules="{ required: false, neverbounce: true }"
              labelFor="display_email"
              labelName="Display Email "
              :vmodel.sync="form.display_email"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />
            <input-text
              :rules="{ required: false }"
              labelFor="group_appointment_url"
              labelName="Appointment Link"
              :vmodel.sync="form.appointment_url"
              formGroupClass="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mb-3"
            />
            <div class="row">
              <div class="col-xxl-6 mt-3 my-profile-bio">
                <div class="profileInfo-block">
                  <h6>Bio</h6>
                  <div class="form-group mb-3">
                    <textarea
                      v-model="form.display_about_page"
                      placeholder="Enter your Bio here..."
                      rows="7"
                      class="profileInfo-field form-control"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row" v-if="display_setting_image">
            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 mb-4">
              <figure>Where can I see this information?</figure>
              <img :src="display_setting_image" class="img-fluid" />
            </div>
          </div>

          <div class="row">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
              <button type="submit" class="univ-btn">
                {{ isUpdate ? "Updating..." : "Update" }}
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "EmployerGroupDisplaySetting",
  props: {
    settings: {
      required: true,
    },
    tabIndex: {
      required: true,
    },
    appointmentUrl: {
      required: false,
    },
  },
  data: () => ({
    form: {
      display_phone: "",
      display_email: "",
      tagline: "",
      group_id: "",
      display_about_page: "",
      appointment_url: "",
    },
    isUpdate: false,
    display_setting_image: null,
  }),
  methods: {
    updateInfo() {
      this.isUpdate = true;
      const url = `v2/update-display-settings`;
      let loader = this.$loading.show();
      console.log("Form Data", this.form);
      Api.put(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isUpdate = false;
          loader.hide();
          this.$refs.observer.reset();
        });
    },
  },
  watch: {
    settings: function (value) {
      if (value) {
        this.form = {
          display_phone: value.display_phone,
          display_email: value.display_email,
          tagline: value.tagline,
          group_id: value.group_id,
          display_about_page: value.display_about_page,
          appointment_url: this.appointmentUrl,
        };
        this.display_setting_image = value.display_setting_image ?? null;
      }
    },
    appointmentUrl: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.form.appointment_url = value;
        }
      },
    },
  },
};
</script>

<template>
  <div class="page-wrapper employerinfo-wrapper">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>Employers & Groups Edit Info</h1>
      </div>
      <div class="employerinfo-card">
        <b-card no-body>
          <b-tabs card>
            <b-tab
              title="General Information"
              @click="
                tabIndex = 0;
                addressType = null;
              "
              active
            >
              <b-card-text>
                <employer-group-general-info
                  :generalInfo="generalInfo"
                  :group-industries="groupIndustries"
                  @onSuccess="getDetail"
                />
              </b-card-text>
            </b-tab>
            <b-tab
              title="Address Information"
              @click="
                tabIndex = 1;
                addressType = 'general';
              "
            >
              <b-card-text>
                <employer-group-address-info
                  :addresses="personalAddresses"
                  @onSuccess="getDetail"
                />
              </b-card-text>
            </b-tab>
            <b-tab
              title="Billing Address"
              @click="
                tabIndex = 2;
                addressType = 'billing';
              "
            >
              <b-card-text>
                <empolyer-group-billing-address-info
                  :billingInfo="billingInfo"
                  :billingAddresses="billingAddresses"
                  @onSuccess="getDetail"
                />
              </b-card-text>
            </b-tab>
            <b-tab
              title="Display Setting"
              @click="
                tabIndex = 2;
                addressType = null;
              "
            >
              <b-card-text>
                <EmployerGroupDisplaySetting
                  :settings="displaySettings"
                  :appointmentUrl="appointmentUrl"
                  tabIndex="2"
                />
              </b-card-text>
            </b-tab>
            <b-tab title="Personalized Sites">
              <b-card-text>
                <EmployerGroupPersonalizedSites :general-info="generalInfo" />
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
    <!-- Add Address Pop up -->
    <employer-group-address-add :type="addressType" @onSuccess="getDetail" />
  </div>
</template>
<script>
import Api from "@/resource/Api";
import EmployerGroupAddressAdd from "./partials/EmployerGroupAddressAdd.vue";
import EmployerGroupAddressInfo from "./detail/EmployerGroupAddressInfo.vue";
import EmployerGroupGeneralInfo from "./detail/EmployerGroupGeneralInfo.vue";
import EmpolyerGroupBillingAddressInfo from "./detail/EmpolyerGroupBillingAddressInfo.vue";
import EmployerGroupDisplaySetting from "./detail/EmployerGroupDisplaySetting.vue";
import EmployerGroupPersonalizedSites from './detail/EmployerGroupPersonalizedSites.vue'

export default {
  name: "EmployerInfoEdit",
  components: {
    EmployerGroupAddressAdd,
    EmployerGroupAddressInfo,
    EmployerGroupGeneralInfo,
    EmpolyerGroupBillingAddressInfo,
    EmployerGroupDisplaySetting,
    EmployerGroupPersonalizedSites,
  },
  data: () => ({
    groupId: "",
    generalInfo: {},
    personalAddresses: [],
    billingInfo: {},
    displaySettings: {},
    billingAddresses: [],
    groupIndustries: [],
    tabIndex: 0,
    addressType: null,
    breadcrumbs:[],
    appointmentUrl: "",
  }),
  methods: {
     getBreadCrumbs() {
      this.breadcrumbItems = [
        {
          title: "Employers & Groups",
          path: {
            name: "EmployerGroupList",
          },
          active: false,
        },
        {
          title: "Employers & Groups Edit Info",
          active: true,
        },
      ];
    },
    getGroupIndustries() {
      let url = "v2/get-employer-industry";
      Api.get(url)
        .then((res) => {
          this.groupIndustries = res.data.data;
        })
        .catch(() => {
          console.log("Something is wrong.");
        });
    },
    async getDetail() {
      this.groupId = atob(this.$route.params.groupId);
      const url = `v2/get-group-details/${this.groupId}`;
      let loader = this.$loading.show();
      await Api.get(url)
        .then((res) => {
          let result = res.data.data;
          this.generalInfo = result.general_info;
          this.personalAddresses = result.address_information;
          this.billingInfo = result.general_info.billing;
          this.billingAddresses = result.billing_address;
        })
        .catch(() => {
          this.generalInfo = {};
          this.personalAddresses = [];
          this.billingInfo = {};
          this.billingAddresses = [];
        })
        .finally(() => {
          loader.hide();
        });
    },

    async getDsipalySettings() {
      const url = `v2/get-display-settings/${this.groupId}`;
      await Api.get(url)
        .then((res) => {
          let result = res.data.data;
          this.displaySettings = result.info;
          this.appointmentUrl = result.appointment_url;
        })
        .catch(() => {
          this.displaySettings = {};
        });
    },
  },
  created() {
    this.getGroupIndustries();
    this.getBreadCrumbs()
  },
  watch: {
    groupIndustries: function (value) {
      if (value) {
        this.getDetail();
        this.getDsipalySettings();
      }
    },
  },
};
</script>
<style src="@/assets/css/employer-groups.css"></style>
